<template>
    <div class="act-form production-task-list">
        <div>
            <div class="d-print-block d-none" v-if="filters.storage && filters.shift">
                <b-row class="print">
                    <b-col md="4">
                        {{filters.storage.name}}
                    </b-col>
                    <b-col md="4">
                        {{date}}
                    </b-col>
                    <b-col md="4">
                        {{filters.shift.name}}
                    </b-col>
                    <hr>
                </b-row>
            </div>
            <div class="d-print-none">
                <b-card no-body class="production-task-list-filters">
                    <template #header>
                        <b-col class="production-task-list-header">
                            <div>
                                <span @click="print">🖨️</span>
                                <span class="refresh" @click="filter">🔄</span>
                            </div>
                            <span class="dropdown-toggle" style="margin-left: auto;" v-b-toggle.filters>
                            <span style="display: inline-block;margin: 0 10px;font-weight: 600;" v-if="filters.storage">
                                {{ filters.storage.name }}
                            </span>
                            <span style="display: inline-block;font-weight: 600;" v-if="filters.date || filters.shift">
                                ({{ filters.date ? moment(filters.date).format('DD.MM.YY') : '' }}<template v-if="filters.date && filters.shift"> / </template>{{ filters.shift ? filters.shift.name : '' }})
                            </span>
                        </span>
                        </b-col>
                    </template>
                    <b-collapse id="filters">
                        <b-card-body>
                            <b-row>
                                <b-col md="4">
                                    <b-form-group description="План на дату">
                                        <input type="date" class="form-control" v-model="filters.date" @input="filter">
                                    </b-form-group>
                                </b-col>
                                <b-col md="4">
                                    <b-form-group description="Цех">
                                        <storage-picker v-model="filters.storage" @input="filter" :allowedOnly="true"></storage-picker>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4">
                                    <b-form-group description="Смена">
                                        <shift-input v-model="filters.shift" @input="filter"></shift-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-md-3">
                                    <b-form-group>
                                        <b-checkbox v-model="filters.heat" @input="filter">Термообработка</b-checkbox>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
            <div class="card-columns" style="column-count: 1">
                <div style="page-break-after:always">
                    <task v-for="task in tasks"
                          :item="task"
                          :key="task.id"
                    ></task>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import forEach from 'lodash/forEach';
    import findKey from 'lodash/findKey';

    import Task from "./Task";
    import moment from 'moment';
    import StoragePicker from "../_common/StoragePicker";
    import ShiftInput from "../_common/ShiftInput";

    import Barcoder from '../../utils/barcode-transition/Barcoder';
    import TransitionHandler from '../../utils/barcode-transition/Handler';
    import { ProductionActByTaskTransition } from '../../utils/barcode-transition/transitions/index';
    import {PRODUCTION_TASKS_ENDPOINT} from "../../utils/endpoints";

    const WITH = [
        'storage',
        'operation',
        'plan.reason',
        'plan.nomenclature.measureUnit',
        'nomenclature.measureUnit',
        'stuff.nomenclature.measureUnit',
        'productionActs'
    ];

    export default {
        components: {
            ShiftInput,
            StoragePicker,
            Task
        },
        computed: {
            date() {
                return moment(this.filters.date).format('DD.MM.YYYY')
            },
        },
        data() {
            return {
                filters: {
                    storage: null,
                    date: new Date,
                    shift: null,
                    heat: false
                },
                tasks: [],
                opened: null
            }
        },
        methods: {
            moment,
            print() {
                window.print()
            },
            isNotAutoProductionPlanReason(reason) {
                return reason.name !== 'auto' && reason.name !== 'auto_additional';
            },
            filter() {
                let storageId = this.filters.storage ? this.filters.storage.id : null;
                let shiftId = this.filters.shift ? this.filters.shift.id : null;
                let date = moment(this.filters.date).format('YYYY-MM-DD');
                let heat = !!this.filters.heat ? 1 : 0;

                localStorage.setItem('production_task_dashboard_filter', JSON.stringify(this.filters))

                if (storageId && date && shiftId) {
                    this.$http.get(PRODUCTION_TASKS_ENDPOINT + '/list/print', {
                            params: {
                                storage_id: storageId,
                                date: date,
                                shift_id: shiftId,
                                heat: heat,
                                with: WITH,
                            }
                        })
                        .then( response => {
                            if ( this.opened ) {
                                this.opened.showCollapse = false;
                                this.opened = null;
                            }
                            // this.dicts = response.data.dicts;
                            this.loaded = true;

                            let tasks = response.data;

                            let barcodes = [];
                            forEach( tasks, ( task, key ) => {
                                let params = { taskId: task.id };
                                let barcode = TransitionHandler.generate( ProductionActByTaskTransition, params );

                                barcodes.push( barcode );
                                tasks[ key ].barcode = barcode;
                            } );

                            Barcoder.get( barcodes ).then( response => {
                                let base64Barcodes = response.data;

                                forEach( base64Barcodes, record => {
                                    let taskKey = findKey( tasks, task => task.barcode === record.barcode );

                                    tasks[ taskKey ].base64Barcode = record.content;
                                } );

                                this.tasks = tasks;
                            } );
                        } );
                }
            }
        },
        mounted() {
            let filtersJson = localStorage.getItem('production_task_dashboard_filter')
            if (filtersJson) {
                this.filters = JSON.parse(filtersJson)
            }

            this.filter()
        },
    }
</script>
<style scoped>

    .is-not-auto-reason {
        background-color: #f8e7f9;
    }

    .table {
        width: 100% !important;
        margin-bottom: 0;
    }

    table.table td:nth-of-type(1) {
        width: 100%;
    }

    .table td, .table th {
        padding: .5rem;
    }

    .table tbody > tr:nth-of-type(1) td {
        border-top: none;
    }

    .card-columns {
        column-count: 2;
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        .card-columns {
            column-count: 1;
        }

        .stuff-card {
            border-top: solid 3px black;
        }
    }

    .selected {
        background-color: #80808085;
    }

    .sub-menu {
        bottom: 0;
        right: 0;
        border: 2px solid #80808085;
        background-color: #80808085;
        padding: 2px 12px;
        z-index: 5;
    }

    .card {
        page-break-inside: avoid;
    }

    .task-card.card {
        border-top: solid 3px black;
    }

    @page {
        size: A4;
    }

    .print {
        font-size: 35px;
        line-height: 45px;
    }

    .production-task-list .production-task-list-header {
        display: flex;
        padding: 0;
    }

    .production-task-list .production-task-list-header span {
        font-size: 18px;
    }

    @media(max-width: 768px) {
        .production-task-list .production-task-list-filters .card-header {
            padding: 0.75rem;
        }

        .production-task-list .production-task-list-header {
            padding: 0;
        }

        .production-task-list .production-task-list-header span {
            font-size: small;
        }
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
